<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    stroke="currentColor"
  >
    <g clip-path="url(#clip0_19914_316378)">
      <path
        d="M1 1H19"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <path
        d="M1 19H19"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <path
        d="M12 11V9C14.89 8.02 17 4.62 17 1H3C3 4.62 5.11 8.02 8 9V11C5.11 11.98 3 15.38 3 19H17C17 15.38 14.89 11.98 12 11Z"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path d="M14 19L10 15L6 19" fill="currentColor" />
      <path d="M14 19L10 15L6 19" stroke-width="1.5" stroke-miterlimit="10" />
    </g>
    <defs>
      <clipPath id="clip0_19914_316378">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
